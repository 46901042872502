import React from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import styled from "styled-components";
import HeroMini from "../components/HeroMini/HeroMini";

const Title = styled.h1`
  ${({ theme }) => theme.common.typography.heroLarge};
  text-transform: uppercase;
  color: ${({ theme }) => theme.common.palette.brownGray};
  line-height: 40px;
`;

const BlogContentContainer = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ol,
  ul {
    padding: 0px 15%;
  }
`;

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  title,
  helmet,
  featuredImage,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <BlogContentContainer>
      {helmet || ""}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <HeroMini
              backgroundImage={featuredImage}
              render={<Title style={{ color: "white" }}>{title}</Title>}
              style={{
                marginTop: "0px",
                height: "300px",
              }}
              fullWidth
            />
            <p>{description}</p>
            <PostContent content={content} />
          </div>
        </div>
      </div>
    </BlogContentContainer>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        featuredImage={post.frontmatter.featuredimage}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
          publicURL
        }
        title
        description
      }
    }
  }
`;
